.page-survey-response {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    box-sizing: border-box;
}

.page-survey-response.page-survey-response-charts {
    background: url('../../../../assets/hydra-response1.png') no-repeat scroll bottom right;
    background-size: 250px;
}

.page-survey-response.page-survey-response-charts .page-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: #A8BAFF;
}

.page-survey-response.page-survey-response-dialog_2 {
    background: url('../../../../assets/hydra-response2-right.png') no-repeat scroll bottom right/106px,
    url('../../../../assets/hydra-response2-left.png') no-repeat scroll bottom left/178px;
}

.page-survey-response.page-survey-response-dialog_3 {

}

.page-survey-response.page-survey-response-dialog_1 {
    background: url('../../../../assets/hydra-response4-right.png') no-repeat scroll bottom right/330px 420px,
    url('../../../../assets/hydra-response4-left.png') no-repeat scroll center left/190px 340px;
}

.page-survey-response .previous-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.page-survey-response .phrases {
    margin-bottom: 1rem;
}

.page-survey-response .phrases .phrase {
    background: rgba(168, 186, 255, 0.1);
    border-radius: 20px;
    width: 530px;
    padding: 20px 50px;
}

.page-survey-response .phrases.trigger .phrase {
    opacity: 0;
}

.page-survey-response .phrases.trigger .phrase.fade-in {
    opacity: 1;
    transition: opacity 4s ease;
}

.page-survey-response .phrases .phrase.phrase2 {
    margin-bottom: 20px;
    margin-left: 100px;
}

.page-survey-response .phrases .phrase.phrase3 {
    margin-bottom: 20px;
}

.page-survey-response .phrases .phrase.phrase4 {
    margin-left: 50px;
}

.page-survey-response .phrases .phrase.phrase5 {
    margin-bottom: 60px;
}

.page-survey-response .phrases .phrase.phrase6 {

}

.page-survey-response .phrases .phrase.phrase7 {
    margin-bottom: 60px;
}

.page-survey-response .phrases .phrase.phrase8 {
    width: 340px;
}

.stat-container {
    display: flex;
    width: 50%;
    justify-content: space-between;

    margin-top: 50px;
    margin-bottom: 50px;
}

.stat-container .stat-item {
    width: 250px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
}

.page-survey-response.page-survey-response-dialog_3 .hydra-animation-response3 {
    position: absolute;
    top: 58%;
    margin-top: -135px;
    margin-right: -850px;

    width: 269px; /* Viewport-width */
    height: 269px; /* Viewport-height */
    background: transparent url('../../../../assets/Hydra-basic.png') 0 0 no-repeat; /* Our sprite as the viewport background */
    background-size: cover;
    animation: hydra2 5s steps(121) infinite; /* The Animation */
}

/* Animation keyframes for the sprite */
@keyframes hydra2 {
    100% {
        background-position: 0 -32499px;
    }
}


@media only screen and (max-width: 768px) {
    .page-survey-response {
        background: none;
        padding-top: 10px;
    }

    .stat-container {
        flex-direction: column;
        margin-top: 0;
    }

    .stat-container .stat-item {
        width: 100%;
    }

    .page-survey-response.page-survey-response-charts {
        background: none;
    }

    .page-survey-response.page-survey-response-charts .phrases {
        display: none;
    }

    .page-survey-response .phrases {
        display: block;
    }

    .page-survey-response .phrases.trigger .phrase,
    .page-survey-response .phrases.trigger .phrase.fade-in {
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        box-sizing: border-box;
        transition: none;
    }

    .page-survey-response.page-survey-response-dialog_3 .hydra-animation-response3 {
        display: none;
    }

    .page-survey-response.page-survey-response-dialog_1 {
        background: none;
    }

    .page-survey-response.page-survey-response-dialog_2 {
        background: none;
    }
}

.page-contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page-contactForm-wrapper {
    width: 800px;
}

@media screen and (max-width: 800px) {
    .page-contactForm-wrapper {
        width: 100%;
    }
}

.page-contactForm .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem;
}

.page-contactForm .page-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
    color: #A8BAFF;
}

@media screen and (max-width: 600px) {
    .page-contactForm .page-header {
        font-size: 28px;
    }
}

.page-contactForm .previous-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.page-contactForm .text-style-1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: rgba(50, 50, 50, 0.7);
}

.page-contactForm .text-style-2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: #A8BAFF;
}

.page-contactForm .button-wrapper button {
    width: 100%;
}

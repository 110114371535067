html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 650px;
}

body {
    font-family: 'Gilroy', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.body-container {

    display: flex;
    flex-direction: column;
    height: 100%;
}

.header-container {
    flex: 0 0 52px;

    background: #F7F9FA;
    padding: 8px 150px;
}

.page-container {
    height: 100%;
    background: #FFFFFF;
}

.page-header {
    font-weight: bold;
    font-size: 48px;
    line-height: 62px;
    text-align: center;
    color: #784A96;
    padding: 0 10px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 60px;
    padding: 0px 150px;

    background: #EAEAEA;
}

.footer-container .copyright {
    color: #B7B7B7;
}

.footer-container .footer-menu {
    display: flex;
}

.footer-container .footer-menu .footer-menu-item {
    margin-left: 20px;
}

.footer-container .footer-menu .footer-menu-item a {
    color: #323232;
}

.slide-routes {
    height: 100%;
}

.btn {
    padding: 18px 40px 17px;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
}

.btn-primary {
    background: #A8BAFF;
    color: #FFFFFF;
}

.btn-primary:hover {
    background: #9C78E7;
}

.btn-primary:focus {
    background: #784A96;
}

.btn-link {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #B7B7B7;
    background: transparent;
    text-transform: none;
}

.btn-outline {
    background: transparent;
    color: #fff;
    border: 4px solid #fff;
}

.control-radio,
.control-checkbox {
    padding: 16px;

    background: #F7F9FA;
    border-radius: 15px;
    margin: 10px 0px;

    font-size: 20px;
    color: #323232;
    display: flex;
    align-items: center;
}

.control-radio:hover,
.control-checkbox:hover {
    background: rgba(168, 186, 255, 0.15);
}

.control-radio input[type="radio"],
.control-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

.control-radio span,
.control-checkbox span {
    margin-left: 10px;
}

.control-radio .circle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid #C4C4C4;
    position: relative;
}

.control-radio input:checked ~ .circle {
    border: 2px solid #9C78E7;
}

.control-radio input:checked ~ .circle:after {
    content: '';
    background: #9C78E7;
    width: 16px;
    display: block;
    height: 16px;
    border-radius: 50%;
    top: 6px;
    position: absolute;
    left: 6px;
}

.control-checkbox .square {
    width: 28px;
    height: 28px;
    border: 2px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
}

.control-checkbox input:checked ~ .square {
    border: 2px solid #9C78E7;
}

.control-checkbox input:checked ~ .square:after {
    content: '';
    border-left: 2px solid #9C78E7;
    border-bottom: 2px solid #9C78E7;
    display: block;
    width: 14px;
    height: 8px;
    transform: rotate(-45deg);
    top: 4px;
    position: absolute;
    right: 4px;
}


.text-center {
    text-align: center;
}

.ReactModal__Content .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.form-control {
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid #B7B7B7;
    box-sizing: border-box;
    border-radius: 5px;
}

.form-error {
    font-size: 12px;
    color: rgb(244, 67, 54);
}

.input-wrapper {
    padding-bottom: 1rem;
}

.input-wrapper .label {
    font-size: 12px;
}

.input-wrapper .form-control {
    padding: 8px;
    width: 100%;
}


@media only screen and (max-width: 768px) {
    .footer-container {
        padding: 20px 0 10px 0;
        display: flex;
        flex-direction: column-reverse;
    }

    .footer-container .copyright {
        padding-top: 10px;
    }

    .footer-container .footer-menu .footer-menu-item {
        text-align: center;
    }
}


.page-welcomeScreen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.btn-begin-survey {
    align-self: center;
}

.content-welcomeScreen {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.phrases {

}
.page-welcomeScreen .phrase {
    padding: 20px;
    background: rgba(168, 186, 255, 0.1);
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;

    position: absolute;

    opacity: 0;
}
.page-welcomeScreen .phrase.fade-in {
    opacity: 1;
    transition: opacity 4s ease;
}
.page-welcomeScreen .phrase1 {
    width: 20%;

    bottom: 20%;
    left: 10%;
}
.page-welcomeScreen .phrase2 {
    width: 25%;
    left: 12%;
    bottom: 40%;
}
.page-welcomeScreen .phrase3 {
    width: 23%;
    bottom: 50%;
    left: 40%;
}
.page-welcomeScreen .phrase4 {
    width: 16%;

    right: 10%;
    bottom: 42%;
}
.page-welcomeScreen .phrase5 {
    width: 22%;

    right: 5%;
    bottom: 30%;
}

.hydra-animation {
    width: 470px;   /* Viewport-width */
    height: 390px;   /* Viewport-height */
    margin: 2em auto;   /* Little margin */
    background: transparent url('../../../assets/Hydra_Doctors_Spritesheet.png') 0 0 no-repeat;   /* Our sprite as the viewport background */
    background-size: cover;
    animation: hydra1 2s steps(41) forwards; /* The Animation */
}


/* Animation keyframes for the sprite */
@keyframes hydra1 {
    100% { background-position: 0 -16010px; }
}

@media only screen and (max-width: 768px) {
    .hydra-animation {
        width: 100%;
        height: auto;
        background: none;
    }
    .phrases {
        display: none;
    }
}


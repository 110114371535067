.page-finishScreen {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.page-finishScreen .finish-top-container {
    height: fit-content;
    display: flex;
}

.page-finishScreen .finish-top-container .hydra-image {
    flex: 0 0 40%;

    background: url('../../../assets/hydra-finish.png') no-repeat scroll center center/contain;
}

.page-finishScreen .finish-top-container .texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem;
}

.page-finishScreen .finish-top-container .text-style-1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: rgba(50, 50, 50, 0.7);
}

.page-finishScreen .finish-top-container .text-style-2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: #A8BAFF;
}

.page-finishScreen .finish-bottom-container {
    height: 50%;
    background: #B0AFDB;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-finishScreen .finish-bottom-container .texts {
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;

    text-align: center;
    color: #FFFFFF;

    margin: 15px 0 40px;
}

.page-finishScreen .finish-bottom-container .texts .text-style-1 {
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    font-weight: normal;

    color: #323232;
}

.page-finishScreen .finish-bottom-container .texts .text-style-1 .open-modal {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.page-finishScreen .finish-bottom-container .text-style-2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #323232;
}

.page-finishScreen .feedback-item {
    display: flex;
    align-items: center;
}

.page-finishScreen .feedback-item .icon {
    margin-right: 10px;
}

.page-finishScreen .feedback-item .text p {
    margin: 0;
}

.feedback-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-form .input-postfix {
    margin-left: 20px;
}

.feedback-form .text-style-5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: rgba(50, 50, 50, 0.8);

    margin-top: 40px;
}

.page-finishScreen .button-wrapper button {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .page-finishScreen {
        height: auto;
    }

    .page-finishScreen .finish-top-container {
        flex-direction: column;
    }

    .page-finishScreen .finish-top-container .hydra-image {
        margin-top: 1rem;
        flex: 0 0 200px;
    }

    .page-finishScreen .finish-top-container .texts {
        padding-left: 20px;
    }

    .page-finishScreen .finish-bottom-container {
        padding: 1rem 0;
    }

    .page-finishScreen .finish-bottom-container .text-style-2 {
        padding-left: 20px;
    }

    .page-finishScreen .texts .text-style-1 {
        font-size: 20px;
    }

    .page-finishScreen .texts .text-style-2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    .page-finishScreen .finish-bottom-container .texts {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .page-finishScreen .finish-bottom-container .text-style-2 {
        font-size: 16px;
    }
}
.page-survey {
    padding: 0 160px;
    width: 100%;
    box-sizing: border-box;
}

.page-survey .survey-name {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-top: 36px;
    padding: 0 10px;
}

.question-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 40px;
    padding-top: 40px;
}

.question-container .question-name {
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    margin-top: 0;
    padding: 0 100px;
}

.question-container .question-control,
.question-container .buttons {
    padding: 0 160px;
}

.question-container .annotation {
    background: #A8BAFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 17px 160px;
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
}

@media (max-width: 1200px) {
    .question-container .question-control,
    .question-container .buttons,
    .question-container .annotation {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.question-container .buttons {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.question-container .annotation .annotation-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #784A96;
    padding: 0 10px;
}

.question-container .annotation .annotation-body {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #FFFFFF;
    padding: 0 10px;
}

.question-container .annotation .annotation-icon {
    width: 90px;
    background: url('../../../assets/charity_icon.png') no-repeat center center / contain;

}

.survey-categories {
    box-sizing: border-box;
    display: flex;
    margin: 35px auto 0 auto;
    position: relative;
    max-width: 750px;
}

@media (max-width: 1200px) {
    .survey-categories {
        padding: 0 60px;
    }
}

.survey-categories .items {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
}

.survey-categories .items .item {
    height: 6px;
    background: rgba(168, 186, 255, 0.2);
    margin: 0 2px;
    border-radius: 5px;
}

.survey-categories .items .item:first-child {
    margin-left: 0;
}

.survey-categories .items .item:last-child {
    margin-right: 0;
}

.survey-categories .items .item > div.category-name {
    position: absolute;
    top: -18px;
    color: #A8BAFF;
}

.survey-categories .items .item > div.highlight {
    height: 100%;
    background: #A8BAFF;
    border-radius: 5px;
    width: 0;
}

.survey-categories .stepper-name {
    margin-left: 20px;
}


.ReactModal__Content h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: rgba(50, 50, 50, 0.8);
}

.ReactModal__Content .buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}

.proceed-overlay-modal {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);

    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

.proceed-modal {
    position: initial;
    inset: 40px;
    border: none;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 10px;
    outline: none;
    padding: 20px;

    width: 700px;
    height: 250px;
    position: initial;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 960px) {
    .question-container .annotation .annotation-icon {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .page-survey {
        padding: 0;
    }

    .survey-categories {
        padding: 10px;
    }

    .question-container .question-name {
        padding: 0 20px;
        font-size: 26px;
    }

    .question-container,
    .question-container .annotation {
        border-radius: 0;
    }

    .question-container .question-control,
    .question-container .buttons,
    .question-container .annotation {
        padding-left: 10px;
        padding-right: 10px;
    }

    .proceed-modal h2 {
        font-size: 20px;
    }

    .page-survey .survey-name {
        margin-top: 26px;
    }

    .question-container .annotation {
        margin-top: 10px;
    }
}

@media screen and (max-width: 420px) {
    .question-container .annotation {
        flex-direction: column;
    }

    .question-container .annotation .btn {
        margin: 10px;
        text-align: center;
    }
}

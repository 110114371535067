.page-beginSurvey {
    height: 100%;
}

.content-beginSurvey {

    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;

    padding-bottom: 40px;
    box-sizing: border-box;
}

.content-beginSurvey .hydra-logo {
    background: url('../../../assets/hydra-beginSurvey.png') no-repeat center bottom/contain;
    width: 100%;
    flex: 1;
}

.page-beginSurvey .phrases {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.page-beginSurvey .phrase {
    padding: 20px;
    background: #B0AFDB;
    border-radius: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #ffffff;
}

.page-beginSurvey .phrase1 {
    width: 680px;
}


@media only screen and (max-width: 768px) {
    .page-beginSurvey .phrases {
        padding-top: 30px;
        padding-bottom: 1rem;
    }

    .page-beginSurvey .phrase {
        font-size: 20px;
    }

    .page-beginSurvey .phrase1 {
        width: calc(100% - 40px);
        border-radius: 0;
    }

    .page-beginSurvey .buttons {
        padding-top: 1rem;
    }

    .content-beginSurvey {
        padding-bottom: 10px;
    }
}
